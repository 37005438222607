  import {
    Observe
  } from '@/utils/from-common'

  // 更新音乐数据执行一些操作
  export const UpdateMusicList = {
    data() {
      return {
        changeData: []
      }
    },

    mounted() {
      Observe.subscribe('collectMusicChangeMusicItem', (data) => {
        this.changeData = [data];

        let videoInfo = this.$store.state.globalVideoInfo;

        if (videoInfo && data && videoInfo.music_id == data.music_id) {
          this.$store.commit('changeVideoInfo', data);
        }

        let globalVideoList = this.$store.state.globalVideoList;

        globalVideoList = this.onUpdate(globalVideoList, data);

        this.$store.commit('changeVideoList', globalVideoList);
      });
    },

    beforeDestroy() {
      Observe.unsubscribe('collectMusicChangeMusicItem');
    },

    methods: {
      // 更新列表数据，scope是修改后的数据，需要替换
      onUpdate(list, scope) {
        return list.map(item => {
          let cId = scope.music_id;

          if (item && item.music_id && item.music_id === cId) {
            return scope;
          }

          return item;
        })
      }
    }
  }

  export default {
    methods: {
      // 切换音乐列表key值，变成音乐播放器可以使用的格式
      // toggleListKey(obj = {}, url_name, music_name = 'name', sup_name = 'suplr_name') {
      //   url_name = url_name ? url_name : (obj.is_buy == '1' ? 'origin_url' : 'audition_url')

      //   let {
      //     [url_name]: url, [music_name]: name, [sup_name]: com_name
      //   } = obj;

      //   return Object.assign({}, obj, {
      //     url,
      //     name,
      //     com_name
      //   })
      // },
    }
  }