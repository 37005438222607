// 分享、下载、收藏、加入购物车
import MusicMixins from '@/mixins/music'
import {
    gMusicMixins
} from '@/mixins/index'
import Deeds from './deeds'
import {
    RequestFile,
    DownloadFileByIframe,
    getFileSuffixByPath
} from '@/utils/function'
import {
    Observe
} from '@/utils/from-common'

const OperationDir = {
    save: '1',
    cancel: '0'
}

export default {
    data() {
        return {
            /**
             * music 音乐
             * songlist 歌单
             * songer 音乐人
             */
            goodType: 'music'
        }
    },

    mixins: [MusicMixins, Deeds, gMusicMixins],

    methods: {
        goShareMusic(scope) {
            let host = location.origin;
            let str = `${host}/musicDetail/${scope.music_id}`

            // scope.audition_url
            this.share(str, scope.music_id).then(() => {
                this.$message.success(this.$t('allMusic.shareSuccessTip'));
                this.$emit('closeDrawer')
            })
        },

        goMusicDetail(scope) {
            const {
                music_id
            } = scope
            this.$router.push(`/musicDetail/${music_id}`);
        },

        goCollectMusic(scope, id, notMusic = true) {
            return new Promise((resolve) => {
                if (!this.$root.checkLoginStatus()) return;

                let newId = id ? id : scope.music_id;

                this.like(scope, newId).then(data => {
                    let is_collected = data.is_collected || '0';
                    let message = is_collected == '0' ? this.$t('allMusic.collectSuccessTip') : this.$t('allMusic.cancelSuccessTip');

                    this.$message.success(message);

                    let newScope = Object.assign({}, data, {
                        is_collected: `${1 - is_collected}`
                    });

                    newScope = this.toggleListKey(newScope);

                    this.$emit('update', newScope);
                    this.$emit('success', newScope);

                    resolve(newScope);

                    if (notMusic) {
                        this.$emit('closeDrawer')

                        //console.log('newScope: ', newScope);
                        Observe.publish('collectMusicChangeMusicItem', newScope);
                    }
                });
            })
        },

        goDownloadMusic(scope) {
            if (!this.$root.checkLoginStatus()) return;

            return new Promise((resolve, reject) => {
                this.$axios.Auth_lookInfo({ 'music_id': scope.music_id }).then(res => {
                    const data = res.data;
                    if (data && data.code == 0) {
                        let newData = data.data ? data.data : {};
                        let goodType = this.$val.goodsType;

                        scope = Object.assign({}, scope, newData, {
                            // 添加商品类型用来激活音乐授权
                            goods_type: goodType ? goodType.package : '2'
                        });
                        if (scope.library_type == 1 && scope.op_download == 1 && scope.op_id == 0) {
                            console.log(scope)
                            if (scope.op_package == 1) {
                                // 直接下载
                                this.donwloadHighMusic(scope);
                            } else {
                                // 需要弹窗
                                this.$store.commit('changeMusicDownloadDialog', true);
                                this.$store.commit('changeCurrentDownloadMusicDetail', scope);
                                resolve(data);
                            }
                        } else if (scope.library_type == 1 && scope.op_download == 0 && scope.op_id == 0) {
                            this.$message.error('高清音乐下载数量已达上限');
                        } else {
                            this.$store.commit('changeMusicDownloadDialog', true);
                            this.$store.commit('changeCurrentDownloadMusicDetail', scope);
                            resolve(data);
                        }
                    } else {
                        reject();
                    }
                }, reject)
            })
        },

        checkDownloadMusic(scope) {
            let params = {
                music_id: scope.music_id
            }

            return new Promise((resolve, reject) => {
                this.$axios.Auth_verify(params).then(res => {
                    const data = res.data;

                    if (data && data.code == 0) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, reject)
            })
        },

        downloadTryMusic(scope) {
            let music_url = scope.audition_url;

            this.$store.commit('changeContentLoading', true);

            this.staticDownloadMusic(scope, music_url, RequestFile).then(() => {
                this.$store.commit('changeContentLoading', false);
                this.$store.commit("changeMusicDownloadDialog", false);
            }).catch(() => {
                this.$store.commit('changeContentLoading', false);
                this.$message.error('下载失败');
            });
        },

        donwloadHighMusic(scope, isDirect) {
            let music_url = scope.origin_url;

            this.$store.commit('changeContentLoading', true);

            if (isDirect) {
                this.staticDownloadMusic(scope, music_url, DownloadFileByIframe).then(() => {
                    this.$store.commit('changeContentLoading', false);
                    this.$store.commit("changeMusicDownloadDialog", false);
                }).catch(() => {
                    this.$store.commit('changeContentLoading', false);
                    this.$message.error('下载失败');
                });
            } else {
                this.checkDownloadMusic(scope).then(() => {
                    this.staticDownloadMusic(scope, music_url, DownloadFileByIframe).then(() => {
                        this.$store.commit('changeContentLoading', false);
                        this.$store.commit("changeMusicDownloadDialog", false);
                    }).catch(() => {
                        this.$store.commit('changeContentLoading', false);
                        this.$message.error('下载失败');
                    });
                }).catch(() => {
                    this.$store.commit('changeContentLoading', false);
                    this.$message.error('下载失败');
                });
            }
        },

        checkFileName(fileName) {
            if (!fileName) return fileName;
            return fileName.replace('\n', '');
        },
        staticDownloadMusic(scope, musicUrl = '', func) {
            let url = musicUrl.split('?');
            let finalUrl = url && url.length > 0 ? url[0] : '';
            let suffix = getFileSuffixByPath(finalUrl);
            let name = scope.music_name ? scope.music_name : scope.name;
            let filename = `${name}.${suffix}`;
            filename = this.checkFileName(filename);
            return new Promise((resolve, reject) => {
                this.download(scope, scope.music_id).then(() => {
                    func = typeof func === 'function' ? func : RequestFile;
                    func(musicUrl, filename).then(() => {
                        this.$message.success('下载成功');
                        resolve();
                    }, reject)
                }, reject)
            })
        },

        // goDownloadMusic_copy(scope) {
        //   let that = this;
        //   let mode, downloadUrl;

        //   if (!this.$root.checkLoginStatus()) return;

        //   if (scope.is_buy) {
        //     mode = this.$val.MusicQuality.high;
        //     downloadUrl = scope.origin_url;
        //   } else {
        //     mode = this.$val.MusicQuality.try;
        //     downloadUrl = scope.audition_url;
        //   }

        //   let suffix = getFileSuffixByPath(downloadUrl);
        //   let filename = `${scope.name}.${suffix}`;

        //   this.$confirm({
        //     title: this.$t('allMusic.downloadAudioTipTitle'),
        //     content: this.$t('allMusic.downloadTryAudioTip'),
        //     onOk() {
        //       return new Promise((resolve, reject) => {
        //         that.download(scope, scope.music_id, mode).then(() => {
        //           RequestFile(downloadUrl, filename).then(resolve, reject)
        //           that.$emit('closeDrawer')
        //         }, reject)
        //       })
        //     }
        //   })
        // },

        goPlayMusic(scope) {
            let type = this.$val.DeedType.tryListen;
            let obj_type = this.$val.ShareAndCollectType[this.goodType];

            this.$store.commit('changeVideoPlaying', false);
            this.$store.commit('changeVideoInfo', this.toggleListKey(scope));

            this.$nextTick(() => {
                setTimeout(() => {
                    this.$store.commit('changeVideoPlaying', true);
                }, 500);
            })

            return new Promise(resolve => {
                if (this.$store.state.login) {
                    this.staticDeed({
                        deed_type: type,
                        obj_type,
                        obj_id: scope.music_id || '',
                        op_type: OperationDir.save
                    })
                }

                resolve();
            })
        },

        goAddMusicToShopCart(scope) {
            if (!this.$root.checkLoginStatus()) return;
            console.log("music " + scope.type);
            if (scope.type == 3) { // 当把 音效加入购物车时，音效不能单独购买，所以 单独处理
                return new Promise((resolve, reject) => {
                    this.$axios.Auth_lookInfo({ 'music_id': scope.music_id }).then(res => {
                        const data = res.data;
                        if (data && data.code == 0) {
                            if (data.data.op_download == 1)
                            {
                                this.$router.push(`/member?menukey=3`);
                            }else{
                                this.$router.push(`/subscribe`);
                                
                            }

                        } else {
                            reject();
                        }
                    }, reject)
                })
            } else {
                this.$store.commit('changeCurrentMusicDetail', scope);

                this.$store.commit('changeMusicDetailDialog', true);

                this.$emit('closeDrawer')
            }

        }
    }
}
