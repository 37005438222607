<template>
  <div class="cart-detail-item">
    <table style="width:100%;">
      <tr v-for="(cItem, key) in data.detail" :key="key">
        <td style="width:70%;padding:12px;">
           <a-row>
            <a-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <img :src="cItem.logo_url" style="width:100%;"/>
            </a-col>
            <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <a-col :span="24" class="ellipsis"><b>{{cItem.music_name}}</b></a-col>
              <a-col :span="24" class="ellipsis">{{cItem.suplr_name}}</a-col>
            </a-col>
            <a-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14">
               <a-col :span="24">
                  <a-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6" >{{$t('music.labelUseMethod')}}:</a-col>
                  <a-col :xs="24" :sm="24" :md="12" :lg="18" :xl="18">{{cItem.use_mode}}</a-col>
                </a-col>
                <a-col :span="24">
                  <a-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6" >{{$t('music.labelUseScene')}}:</a-col>
                  <a-col :xs="24" :sm="24" :md="12" :lg="18" :xl="18">{{cItem.auth_scene | toAuthScene}}</a-col>
                </a-col>
                <a-col :span="24">
                  <a-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6" >{{$t('music.labelAuthArea')}}:</a-col>
                  <a-col :xs="24" :sm="24" :md="12" :lg="18" :xl="18">{{cItem.auth_area | toArea}}</a-col>
                </a-col>
                <a-col :span="24">
                  <a-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6" >{{$t('music.labelAuthTime')}}:</a-col>
                  <a-col :xs="24" :sm="24" :md="12" :lg="18" :xl="18">{{cItem.auth_time | toAuthPeriod}}</a-col>
                </a-col>
            </a-col>
           </a-row>
        </td>
        <td v-if="key == 0" :rowspan="data.detail.length" style="width:15%;border-left: 1px solid #E2E5E9;text-align:center;">
           <span>¥{{data.order_amount}}</span>
        </td>
        <td v-if="key == 0" :rowspan="data.detail.length" style="width:15%;border-left: 1px solid #E2E5E9;text-align:center;">
          <a-col :span="24">
            <span v-if="data.status==0">待支付</span>
            <span v-if="data.status==1">已取消</span>
            <span v-if="data.status==2">已取消</span>
            <span v-if="data.status==3">支付中</span>
            <span v-if="data.status==4">支付成功</span>
          </a-col>
          <a-col :span="24" style="margin-top:10px;">
            <router-link :to="`/order/${data.order_id}`" target="_blank"><span class="label-item-span">查看详情</span></router-link>
          </a-col>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
  import {AuthAreaOptions, BusinessSceneOptions, AuthTimeOptions} from '@/config/options';
  import {toOptionName} from '@/utils/function';
  import MusicPlayIcon from '@/pages/components/music/MusicPlayIcon.vue'

  export default {
    data() {
      return {

      }
    },

    props: {
      data: {
        type: Object,
        default() {
          return {}
        }
      },
      canPlay: {
        type: Boolean,
        default: false
      }
    },
    filters: {
      toArea(value) {
        return toOptionName(AuthAreaOptions, value);
      },

      toAuthScene(value) {
        return toOptionName(BusinessSceneOptions, value);
      },

      toAuthPeriod(value) {
        return toOptionName(AuthTimeOptions, value);
      }
    },
    components: {
      MusicPlayIcon
    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
</style>
